import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChangePassword from 'src/components/ChangePassword';
import { Alert } from '@material-ui/lab';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    background:
      'linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);'
  },
  wrapperContent: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({
  children,
  budget,
  getBudget,
  logout,
  user,
  isLoading,
  errorChangePassword,
  changePassword
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [isShowMessage, setShowMessage] = useState(false);

  const handleHideMessage = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowMessage(false);
  }, []);

  const handleShowMessage = useCallback(() => {
    setShowMessage(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowForm(false);
  }, []);

  const handleClickItem = useCallback((action) => {
    if (action === 'change-password') {
      setShowForm(true);
    }
    if (action === 'logout') {
      logout();
    }
  }, []);

  useEffect(() => {
    getBudget();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          user={user}
          onClickItem={handleClickItem}
        />
        {/* Change Password form */}
        {showForm && (
          <ChangePassword
            isLoading={isLoading}
            errorMessage={errorChangePassword}
            onChangePassword={changePassword}
            onClose={handleClose}
            onSuccess={handleShowMessage}
          />
        )}
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          budget={budget}
          onLogout={logout}
          user={user}
        />
        <Snackbar
          open={isShowMessage}
          autoHideDuration={5000}
          onClose={handleHideMessage}
        >
          <Alert severity="success">Password changed successfully!</Alert>
        </Snackbar>
        <div className={classes.wrapperContent}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  budget: PropTypes.object,
  getBudget: PropTypes.func,
  logout: PropTypes.func,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  errorChangePassword: PropTypes.string,
  changePassword: PropTypes.func
};

DashboardLayout.defaultProps = {
  getBudget: () => {},
  logout: () => {},
  budget: {},
  user: {},
  isLoading: false,
  errorChangePassword: '',
  changePassword: () => {}
};

export default DashboardLayout;
