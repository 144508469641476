import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserInfo from './UserInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 72,
    width: 255,
    [theme.breakpoints.down('md')]: {
      width: 70
    }
  },
  toolbar: {
    padding: 0,
    justifyContent: 'space-between'
  },
  textEmail: {
    opacity: 0.5
  }
}));

const TopBar = (props) => {
  const { user, className, onMobileNavOpen, onClickItem, ...rest } = props;
  const classes = useStyles();

  return (
    <AppBar
      position="absolute"
      color="transparent"
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden mdDown>
          <UserInfo user={user} onClickItem={onClickItem} />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onClickItem: PropTypes.func,
  user: PropTypes.object
};

TopBar.defaultProps = {
  onClickItem: () => {}
};

export default TopBar;
