import { put, call, takeLatest } from 'redux-saga/effects';
import { getBrandBudget } from 'src/api-client';
import {
  getBudgetFailed,
  getBudgetSuccess,
  GET_BUDGET_REQUEST
} from 'src/redux/actions/Budget';

export function* getBudgetWorker() {
  try {
    const response = yield call(getBrandBudget);
    yield put(getBudgetSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getBudgetFailed(error));
  }
}

export function* getBudgetWatcher() {
  yield takeLatest(GET_BUDGET_REQUEST, getBudgetWorker);
}
