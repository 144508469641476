import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
  Avatar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textEmail: {
    opacity: 0.5
  },
  paper: {
    background: '#292929',
    width: 170,
    borderRadius: 0
  },
  menuItem: {
    fontSize: 14
  }
}));

const UserInfo = (props) => {
  const { user, onClickItem } = props;
  const classes = useStyles();
  const [showMenu, setShowMenu] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggleMenu = useCallback(() => {
    setShowMenu((prevOpen) => !prevOpen);
  }, []);

  const handleClickMenuItem = useCallback((action) => {
    handleToggleMenu();
    onClickItem(action);
  }, []);

  return (
    <>
      <Box
        style={{ cursor: 'pointer' }}
        ref={anchorRef}
        height={72}
        display="flex"
        flexDirection="colum"
        alignItems="center"
        justifyContent="space-between"
        pl={0}
        onClick={handleToggleMenu}
      >
        <Avatar variant="rounded" alt={user.name} width={44} height={44}>
          {user.name.charAt(0)}
        </Avatar>
        <Box pl={1.3}>
          <Typography align="left" color="textPrimary" variant="h5">
            {user.name}
          </Typography>
          <Typography
            align="left"
            color="textPrimary"
            variant="body1"
            className={classes.textEmail}
          >
            {user.email}
          </Typography>
        </Box>
      </Box>

      {/* Menu items of User  */}
      <Popper open={showMenu} anchorEl={anchorRef.current}>
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleToggleMenu}>
            <MenuList>
              <MenuItem
                onClick={() => handleClickMenuItem('change-password')}
                className={classes.menuItem}
              >
                Change Password
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => handleClickMenuItem('logout')}
              >
                Logout
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

UserInfo.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }),
  onClickItem: PropTypes.func
};

UserInfo.defaultProps = {
  user: {
    name: '',
    email: ''
  },
  onClickItem: () => {}
};

export default UserInfo;
