import { all } from 'redux-saga/effects';
import { changePasswordWatcher, loginWatcher } from './Auth';
import {
  getPreshowsWatcher,
  getPreshowDetailWatcher,
  getAnalyticsWatcher,
  getDemographicsWatcher,
  getWidgetsWatcher,
  getSurveyResultsWatcher
} from './Preshow';
import { getBudgetWatcher } from './Budget';

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    // Preshow
    getPreshowsWatcher(),
    getPreshowDetailWatcher(),
    getAnalyticsWatcher(),
    getDemographicsWatcher(),
    getWidgetsWatcher(),
    getSurveyResultsWatcher(),
    // Budgets
    getBudgetWatcher(),
    changePasswordWatcher()
  ]);
}
