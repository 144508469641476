import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { install } from 'resize-observer';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from 'src/store';
import * as serviceWorker from './serviceWorker';
import App from './App';

//  Fixing cash app on Safari 12
// Unhandled Runtime Error ReferenceError: Can't find variable: ResizeObserver on Safari
install();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
