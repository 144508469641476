import React from 'react';
import {
  Box,
  colors,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { numberWithCommas } from 'src/helpers/format';

const useStyles = makeStyles(() => ({
  container: {
    color: colors.common.white,
    padding: 30,
    borderTop: 'solid 1px rgb(216 216 216 / 50%)'
  },
  budget: {
    display: 'flex',
    opacity: 0.5,
    justifyContent: 'space-between',
    paddingTop: 10,
    '& p': {
      fontSize: 12
    }
  },
  spentPercent: {
    fontSize: 12
  },
  spentValue: {
    fontSize: 18,
    padding: '7px 0'
  }
}));

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

const Budget = ({ budget, spent, spentPercentage }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.spentPercent}>
        Spent({spentPercentage}%)
      </Typography>
      <Typography className={classes.spentValue}>
        ${numberWithCommas(spent)}
      </Typography>
      <BorderLinearProgress variant="determinate" value={spentPercentage} />

      <Box className={classes.budget}>
        <Typography>Budget</Typography>
        <Typography>${numberWithCommas(budget)}</Typography>
      </Box>
    </Box>
  );
};

Budget.propTypes = {
  budget: PropTypes.number,
  spent: PropTypes.number,
  spentPercentage: PropTypes.number
};

Budget.defaultProps = {
  budget: 0,
  spent: 0,
  spentPercentage: 0
};

export default Budget;
