import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from 'src/constants';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer && state.authReducer.isAuthenticated
  );

  if (!isAuthenticated) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
