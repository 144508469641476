export const GET_BUDGET_REQUEST = 'GET_BUDGET_REQUEST';
export const GET_BUDGET_SUCCESS = 'GET_BUDGET_SUCCESS';
export const GET_BUDGET_FAILED = 'GET_BUDGET_FAILED';

export const getBudgetRequest = (params, callback) => {
  return {
    type: GET_BUDGET_REQUEST,
    payload: { params, callback }
  };
};

export const getBudgetSuccess = (data) => {
  return {
    type: GET_BUDGET_SUCCESS,
    payload: { data }
  };
};

export const getBudgetFailed = (error) => {
  return {
    type: GET_BUDGET_FAILED,
    payload: { error }
  };
};
