import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from 'src/constants';
import LoadingScreen from 'src/components/LoadingScreen';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#212121',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 12, 4, 4),
    minWidth: 545,
    position: 'relative'
  },
  input: {
    '& .MuiFormHelperText-root': {
      fontSize: 14
    },
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& input': {
      fontSize: 14,
      padding: theme.spacing(1.4),
      fontFamily: 'SourceSansProRegular',
      backgroundColor: 'rgba(156, 165, 236, 0.04)'
    }
  },
  label: {
    marginBottom: theme.spacing(0.8)
  },
  buttonSubmit: {
    height: 44,
    textTransform: 'capitalize',
    '& .MuiButton-label': {
      fontSize: 14,
      fontFamily: 'SourceSansProSemiBold'
    }
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  closeBtn: {
    position: 'absolute',
    right: 5,
    top: 15
  },
  alert: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const ChangePassword = ({
  onClose,
  onChangePassword,
  onSuccess,
  isLoading,
  errorMessage
}) => {
  const classes = useStyles();
  const [isShowError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        currentPassword: Yup.string()
          .max(255)
          .required(ErrorMessage.CURRENT_PASSWORD_REQUIRED),
        newPassword: Yup.string()
          .max(255)
          .required(ErrorMessage.NEW_PASSWORD_REQUIRED),
        confirmPassword: Yup.string()
          .max(255)
          .required(ErrorMessage.CONFIRM_PASSWORD_REQUIRED)
          .oneOf(
            [Yup.ref('newPassword'), null],
            ErrorMessage.PASSWORD_NOT_MATCH
          )
      }),
    onSubmit: ({ currentPassword, newPassword }, { setSubmitting }) => {
      onChangePassword({ currentPassword, newPassword }, (data, err) => {
        if (err) {
          setSubmitting(false);
          setShowError(true);
        } else {
          onClose();
          onSuccess();
        }
      });
    }
  });

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values
  } = formik;

  return (
    <Modal className={classes.modal} open>
      <Box className={classes.paper}>
        <Button className={classes.closeBtn} onClick={onClose}>
          <img
            style={{ width: 19 }}
            src="/static/images/home/close.png"
            alt="close"
          />
        </Button>
        <Typography variant="h2" className={classes.title}>
          Change Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" mb={2}>
            <Typography variant="h5" className={classes.label}>
              Current Password
            </Typography>
            <TextField
              name="currentPassword"
              type="password"
              className={classes.input}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
            />
          </Box>
          <Box display="flex" flexDirection="column" mb={2}>
            <Typography variant="h5" className={classes.label}>
              New Password
            </Typography>
            <TextField
              type="password"
              name="newPassword"
              className={classes.input}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />
          </Box>
          <Box display="flex" flexDirection="column" mb={2}>
            <Typography variant="h5" className={classes.label}>
              Confirm New Password
            </Typography>
            <TextField
              type="password"
              className={classes.input}
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            {isLoading && <LoadingScreen />}
            {!isLoading && !!errorMessage && isShowError && (
              <Alert className={classes.alert} severity="error">
                {errorMessage}
              </Alert>
            )}
          </Grid>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonSubmit}
              type="submit"
              disabled={isSubmitting}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

ChangePassword.propTypes = {
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  onChangePassword: PropTypes.func,
  errorMessage: PropTypes.string,
  onSuccess: PropTypes.func
};

ChangePassword.defaultProps = {
  onClose: () => {},
  onChangePassword: () => {},
  isLoading: false,
  onSuccess: () => {}
};

export default ChangePassword;
