import { takeEvery, put, call } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  loginSuccess,
  loginFailed,
  changePasswordSuccess,
  changePasswordFailed,
  CHANGE_PASSWORD_REQUEST
} from 'src/redux/actions/Auth';
import { login, changePassword } from 'src/api-client';

/**
 * Worker saga will be fired on LOGIN_ACTION actions
 *
 * @param {Object} action
 * - params: Input data to call API
 * - callback: The callback function handle when finish
 */
export function* loginWorker(action) {
  const { params, callback } = action.payload || {};
  try {
    const response = yield call(login, params);
    if (!response || !response.data) {
      throw new Error('Login failed.');
    }

    yield put(loginSuccess(response.data));
    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(loginFailed(error || e));
    if (callback) {
      callback(null, error);
    }
  }
}

/**
 * Starts loginWorker on each dispatched LOGIN_ACTION action
 */
export function* loginWatcher() {
  yield takeEvery(LOGIN_REQUEST, loginWorker);
}

/**
 * Worker saga will be fired on CHANGE_PASWORD_ACTION actions
 *
 * @param {Object} action
 * - params: Input data to call API
 * - callback: The callback function handle when finish
 */
export function* changePasswordWorker(action) {
  const { params, callback } = action.payload || {};
  try {
    const response = yield call(changePassword, params);
    if (!response || !response.data) {
      throw new Error('Change password failed.');
    }

    yield put(changePasswordSuccess(response.data));
    if (callback) {
      callback(response.data, null);
    }
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(changePasswordFailed(error || e));
    if (callback) {
      callback(null, error || e);
    }
  }
}

/**
 * Starts loginWorker on each dispatched LOGIN_ACTION action
 */
export function* changePasswordWatcher() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordWorker);
}
