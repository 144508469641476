import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { LOGOUT_REQUEST } from 'src/redux/actions/Auth';
import { PERSIST_KEY } from 'src/constants';
import { persistReducer } from 'redux-persist';
import authReducer from './Auth';
import preshowReducer from './Preshow';
import budgetReducer from './Budget';

const persistConfig = {
  key: PERSIST_KEY,
  storage,
  blacklist: ['preshowReducer', 'budgetReducer', 'authReducer']
};

const authPersistConfig = {
  key: `${PERSIST_KEY}:authReducer`,
  storage,
  blacklist: ['loginError', 'isLoading']
};

const appReducer = combineReducers({
  authReducer: persistReducer(authPersistConfig, authReducer),
  preshowReducer,
  budgetReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    // reset the state of a Redux store
    storage.removeItem(`persist:${PERSIST_KEY}`);
    storage.removeItem(`persist:${PERSIST_KEY}:authReducer`);
    state = undefined;
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
