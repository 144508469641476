const moment = require('moment');
const { MOMENT_FORMAT } = require('src/constants');
/**
 * Format number by commas
 *
 * @param {number} number
 */
export const numberWithCommas = (number) => {
  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Format seconds to 'H:mm:ss' or 'mm:ss'
 *
 * @param {number} seconds
 * @returns
 */
export const formatTime = (seconds) => {
  if (!seconds || Number.isNaN(seconds)) {
    return '00:00';
  }
  const formatType =
    seconds < 3600 ? MOMENT_FORMAT.SECONDARY_TIME : MOMENT_FORMAT.PRIMARY_TIME;

  return moment().startOf('day').seconds(seconds).format(formatType);
};

/**
 * Format time and add charactor
 *
 * @param {number} seconds
 * @returns
 */
export const formatTimeHMS = (seconds) => {
  if (!seconds || Number.isNaN(seconds)) {
    return '0s';
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds();

  return `${hh ? `${hh}h` : ''}${mm ? `:${mm}m` : ''}${
    ss ? `:${ss}s` : ''
  }`.replace(/^:|:$/g, '');
};
