export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const loginRequest = (params, callback) => {
  return {
    type: LOGIN_REQUEST,
    payload: {
      params,
      callback
    }
  };
};

export const logoutRequest = (params, callback) => {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      params,
      callback
    }
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user
    }
  };
};

export const loginFailed = (error) => {
  return {
    type: LOGIN_FAILED,
    payload: {
      error
    }
  };
};

export const changePasswordRequest = (params, callback) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: {
      params,
      callback
    }
  };
};

export const changePasswordSuccess = (data) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { data }
  };
};

export const changePasswordFailed = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILED,
    payload: {
      error
    }
  };
};
