import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getPreshowList,
  getPreshowDetail,
  getSpotAnalytics,
  getPreshowDemographics,
  getPreshowWidgets,
  getSurveyResults
} from 'src/api-client';
import {
  GET_PRESHOWS_REQUEST,
  GET_PRESHOW_DETAIL_REQUEST,
  GET_ANALYTICS_REQUEST,
  GET_DEMOGRAPHICS_REQUEST,
  GET_WIDGETS_REQUEST,
  getPreshowsFailed,
  getPreshowsSuccess,
  getPreshowDetailSuccess,
  getPreshowDetailFailed,
  getAnalyticsSuccess,
  getAnalyticsFailed,
  getDemographicsSuccess,
  getDemographicsFailed,
  getWidgetsSuccess,
  getWidgetsFailed,
  getSurveyResultsSuccess,
  getSurveyResultsFailed,
  GET_SURVEY_RESULTS_REQUEST
} from 'src/redux/actions/Preshow';

/**
 * Handle get PreShow list
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetPreshowList(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getPreshowList, params);
    yield put(getPreshowsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getPreshowsFailed(error || e));
  }
}

export function* getPreshowsWatcher() {
  yield takeLatest(GET_PRESHOWS_REQUEST, handleGetPreshowList);
}

/**
 * Handle get PreShow detail
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetPreshow(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getPreshowDetail, params);
    yield put(getPreshowDetailSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getPreshowDetailFailed(error || e));
  }
}

export function* getPreshowDetailWatcher() {
  yield takeLatest(GET_PRESHOW_DETAIL_REQUEST, handleGetPreshow);
}

/**
 * Handle get Spot analytics
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetAnalytics(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getSpotAnalytics, params);
    yield put(getAnalyticsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getAnalyticsFailed(error || e));
  }
}

export function* getAnalyticsWatcher() {
  yield takeLatest(GET_ANALYTICS_REQUEST, handleGetAnalytics);
}

/**
 * Handle get demographics
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetDemographics(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getPreshowDemographics, params);
    yield put(getDemographicsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getDemographicsFailed(error || e));
  }
}

export function* getDemographicsWatcher() {
  yield takeLatest(GET_DEMOGRAPHICS_REQUEST, handleGetDemographics);
}

/**
 * Handle get widgets
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetWidgets(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getPreshowWidgets, params);
    yield put(getWidgetsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getWidgetsFailed(error || e));
  }
}

export function* getWidgetsWatcher() {
  yield takeLatest(GET_WIDGETS_REQUEST, handleGetWidgets);
}

/**
 * Handle get survey results
 *
 * @param {Object} action
 * - payload: The params and callback is passed from component
 */
export function* handleGetSurveyResults(action) {
  const { params } = action.payload;
  try {
    const response = yield call(getSurveyResults, params);
    yield put(getSurveyResultsSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;
    yield put(getSurveyResultsFailed(error || e));
  }
}

export function* getSurveyResultsWatcher() {
  yield takeLatest(GET_SURVEY_RESULTS_REQUEST, handleGetSurveyResults);
}
