import { connect } from 'react-redux';
import { getBudgetRequest } from 'src/redux/actions/Budget';
import { changePasswordRequest, logoutRequest } from 'src/redux/actions/Auth';
import Dashboard from 'src/layouts/DashboardLayout';
import { getErrorMessage } from 'src/helpers/error-message';

const mapStateToProps = (state) => {
  const { budget, error } = state.budgetReducer || {};
  const { user, isLoading, error: errorChangePassword } =
    state.authReducer || {};

  return {
    isLoading,
    budget,
    user,
    errorMessage: getErrorMessage(error),
    errorChangePassword: getErrorMessage(errorChangePassword)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBudget: () => dispatch(getBudgetRequest()),
    logout: () => dispatch(logoutRequest()),
    changePassword: (params, callback) =>
      dispatch(changePasswordRequest(params, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
