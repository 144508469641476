import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const { width, height } = props;
  return (
    <img
      alt="Logo"
      width={width}
      height={height}
      src="/static/logo.png"
      {...props}
    />
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Logo.defaultProps = {
  width: 150,
  height: 27
};

export default Logo;
