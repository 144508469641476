export default {
  h1: {
    fontSize: 36,
    fontFamily: 'SourceSansProRegular'
  },
  h2: {
    fontSize: 24,
    fontFamily: 'SourceSansProSemiBold'
  },
  h3: {
    fontSize: 20,
    fontFamily: 'SourceSansProSemiBold'
  },
  h4: {
    fontSize: 16,
    fontFamily: 'SourceSansProSemiBold'
  },
  h5: {
    fontSize: 14,
    fontFamily: 'SourceSansProSemiBold'
  },
  h6: {
    fontSize: 13,
    fontFamily: 'SourceSansProSemiBold'
  },
  body1: {
    fontSize: 12,
    fontFamily: 'SourceSansProRegular'
  },
  caption: {
    fontSize: 18,
    fontFamily: 'SourceSansProSemiBold'
  }
};
