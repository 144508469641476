import axios from 'axios';
import { store } from 'src/store';
import ENDPOINT from './ApiEndpoint';

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_TOKEN,
  REACT_APP_API_USER
} = process.env;

const client = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  timeout: 30000
});

/**
 * Get token from global state
 *
 * @returns
 */
const authHeader = () => {
  const { user = {} } = store.getState().authReducer || {};

  return {
    api_user: REACT_APP_API_USER,
    api_token: REACT_APP_API_TOKEN,
    token: user.token,
    user_id: user.id
  };
};

/**
 * Handle request server to login with email and password
 *
 * @param {Objetc} param
 *
 * @returns
 */
export const login = async ({ email, password }) => {
  const response = await client.post(ENDPOINT.LOGIN, {
    email,
    password,
    kind: 'BRAND'
  });

  return response;
};

/**
 * Handle request server get preshow detail
 *
 * @returns
 */
export const getBrandBudget = async () => {
  const response = await client.get(ENDPOINT.BUDGET, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get preshow list
 * - offset: current index
 * - limit: quantity items
 *
 * @returns
 */
export const getPreshowList = async ({ offset = 0, limit = 10 } = {}) => {
  const response = await client.get(ENDPOINT.PRESHOWS, {
    params: { offset, limit },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get preshow detail
 *
 * @param {Object} param
 * - Id: The id of preshow
 *
 * @returns
 */
export const getPreshowDetail = async ({ id } = {}) => {
  const response = await client.get(`${ENDPOINT.PRESHOWS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get spot analytics by spot id
 *
 * @param {Object} param
 * - id: The spot id
 * - start: The date start
 * - end: The date end
 *
 * @returns
 */
export const getSpotAnalytics = async ({ id, start, end } = {}) => {
  const response = await client.get(`${ENDPOINT.SPOT}/${id}/analytics`, {
    params: { start, end },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get preshow demographics by preshow id
 *
 * @param {Object} param
 * - id: The preshow id
 * - start: The date start
 * - end: The date end
 *
 * @returns
 */
export const getPreshowDemographics = async ({ id, start, end } = {}) => {
  const response = await client.get(`${ENDPOINT.PRESHOWS}/${id}/demographics`, {
    params: { start, end },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get preshow widgets by preshow id
 *
 * @param {Object} param
 * - id: The preshow id
 * - start: The date start
 * - end: The date end
 *
 * @returns
 */
export const getPreshowWidgets = async ({ id, start, end } = {}) => {
  const response = await client.get(`${ENDPOINT.PRESHOWS}/${id}/widgets`, {
    params: { start, end },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get survey results
 * - offset: current index
 * - limit: quantity items
 *
 * @returns
 */
export const getSurveyResults = async ({ start, end } = {}) => {
  const response = await client.get(ENDPOINT.SURVEY_RESULTS, {
    params: { start, end },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to change password
 *
 * @param {Objetc} param
 *
 * @returns
 */
export const changePassword = async ({ currentPassword, newPassword }) => {
  const response = await client.post(
    ENDPOINT.CHANGE_PASSWORD,
    {
      currentPassword,
      newPassword
    },
    { headers: authHeader() }
  );

  return response;
};
