// Get PreShow list
export const GET_PRESHOWS_REQUEST = 'GET_PRESHOWS_REQUEST';
export const GET_PRESHOWS_SUCCESS = 'GET_PRESHOWS_SUCCESS';
export const GET_PRESHOWS_FAILED = 'GET_PRESHOWS_FAILED';
// Get PreShow detail
export const GET_PRESHOW_DETAIL_REQUEST = 'GET_PRESHOW_DETAIL_REQUEST';
export const GET_PRESHOW_DETAIL_SUCCESS = 'GET_PRESHOW_DETAIL_SUCCESS';
export const GET_PRESHOW_DETAIL_FAILED = 'GET_PRESHOW_DETAIL_FAILED';

// Get analytics
export const GET_ANALYTICS_REQUEST = 'GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILED = 'GET_ANALYTICS_FAILED';

// Get demographics
export const GET_DEMOGRAPHICS_REQUEST = 'GET_DEMOGRAPHICS_REQUEST';
export const GET_DEMOGRAPHICS_SUCCESS = 'GET_DEMOGRAPHICS_SUCCESS';
export const GET_DEMOGRAPHICS_FAILED = 'GET_DEMOGRAPHICS_FAILED';

// Get widgets
export const GET_WIDGETS_REQUEST = 'GET_WIDGETS_REQUEST';
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS';
export const GET_WIDGETS_FAILED = 'GET_WIDGETS_FAILED';

// Get survey results
export const GET_SURVEY_RESULTS_REQUEST = 'GET_SURVEY_RESULTS_REQUEST';
export const GET_SURVEY_RESULTS_SUCCESS = 'GET_SURVEY_RESULTS_SUCCESS';
export const GET_SURVEY_RESULTS_FAILED = 'GET_SURVEY_RESULTS_FAILED';

// Get PreShow list
export const getPreshowsRequest = (params, callback) => {
  return {
    type: GET_PRESHOWS_REQUEST,
    payload: { params, callback }
  };
};

export const getPreshowsSuccess = (data) => {
  return {
    type: GET_PRESHOWS_SUCCESS,
    payload: { data }
  };
};

export const getPreshowsFailed = (error) => {
  return {
    type: GET_PRESHOWS_FAILED,
    payload: { error }
  };
};

// Get PreShow detail
export const getPreshowDetailRequest = (params, callback) => {
  return {
    type: GET_PRESHOW_DETAIL_REQUEST,
    payload: { params, callback }
  };
};

export const getPreshowDetailSuccess = (data) => {
  return {
    type: GET_PRESHOW_DETAIL_SUCCESS,
    payload: { data }
  };
};

export const getPreshowDetailFailed = (error) => {
  return {
    type: GET_PRESHOW_DETAIL_FAILED,
    payload: { error }
  };
};

// Get spot analytics
export const getAnalyticsRequest = (params, callback) => {
  return {
    type: GET_ANALYTICS_REQUEST,
    payload: { params, callback }
  };
};

export const getAnalyticsSuccess = (data) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    payload: { data }
  };
};

export const getAnalyticsFailed = (error) => {
  return {
    type: GET_ANALYTICS_FAILED,
    payload: { error }
  };
};

// Get demographics
export const getDemographicsRequest = (params, callback) => {
  return {
    type: GET_DEMOGRAPHICS_REQUEST,
    payload: { params, callback }
  };
};

export const getDemographicsSuccess = (data) => {
  return {
    type: GET_DEMOGRAPHICS_SUCCESS,
    payload: { data }
  };
};

export const getDemographicsFailed = (error) => {
  return {
    type: GET_DEMOGRAPHICS_FAILED,
    payload: { error }
  };
};

// Get Wedgets
export const getWidgetsRequest = (params, callback) => {
  return {
    type: GET_WIDGETS_REQUEST,
    payload: { params, callback }
  };
};

export const getWidgetsSuccess = (data) => {
  return {
    type: GET_WIDGETS_SUCCESS,
    payload: { data }
  };
};

export const getWidgetsFailed = (error) => {
  return {
    type: GET_WIDGETS_FAILED,
    payload: { error }
  };
};

// Get survey result
export const getSurveyResultsRequest = (params, callback) => {
  return {
    type: GET_SURVEY_RESULTS_REQUEST,
    payload: { params, callback }
  };
};

export const getSurveyResultsSuccess = (data) => {
  return {
    type: GET_SURVEY_RESULTS_SUCCESS,
    payload: { data }
  };
};

export const getSurveyResultsFailed = (error) => {
  return {
    type: GET_SURVEY_RESULTS_FAILED,
    payload: { error }
  };
};
