/* eslint-disable global-require */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import rootReducer from 'src/redux/reducers';
import rootSaga from 'src/redux/saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
if (process.env.REACT_APP_NODE_ENV === 'development') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  sagaMiddleware.run(rootSaga);
  return store;
}

export const store = configureStore();
export const persistor = persistStore(store);
