import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/containers/layouts/Dashboard';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/RoutesGuard/AuthGuard';
import GuestGuard from 'src/components/RoutesGuard/GuestGuard';
import { ROUTES } from 'src/constants';

const NotFoundPage = lazy(() => import('src/pages/NotFoundPage'));
const LoginPage = lazy(() => import('src/containers/login'));
const HomePage = lazy(() => import('src/containers/preshows'));
const PreshowDetailPage = lazy(() =>
  import('src/containers/preshows/PreShowDetail')
);

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={`${route.path}-${index}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: ROUTES.NOTFOUND,
    layout: MainLayout,
    component: NotFoundPage
  },
  {
    exact: true,
    guard: GuestGuard,
    path: ROUTES.LOGIN,
    layout: MainLayout,
    component: LoginPage
  },
  {
    exact: true,
    guard: AuthGuard,
    path: ROUTES.PRESHOW,
    component: PreshowDetailPage
  },
  {
    path: ROUTES.DEFAULT,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: ROUTES.HOME,
        component: HomePage
      },
      {
        exact: true,
        path: ROUTES.DEFAULT,
        component: () => <Redirect to={ROUTES.HOME} />
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: ROUTES.DEFAULT,
        component: () => <Redirect to={ROUTES.HOME} />
      },
      {
        component: () => <Redirect to={ROUTES.NOTFOUND} />
      }
    ]
  }
];

export default routes;
