import {
  GET_BUDGET_FAILED,
  GET_BUDGET_REQUEST,
  GET_BUDGET_SUCCESS
} from '../actions/Budget';

export const initialState = {
  isLoading: false,
  budget: {},
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_BUDGET_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        budget: data
      };
    }

    case GET_BUDGET_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error
      };
    }

    default:
      return state;
  }
};
