import React, { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  IconButton,
  Divider
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { NAVBAR_ROUTES } from 'src/constants';
import Logo from 'src/components/Logo';
import Icon from 'src/components/Icon';
import NavItem from './NavItem';
import Budget from './Budget';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 280,
    backgroundColor: theme.palette.background.dark
  },
  desktopDrawer: {
    width: 280,
    height: '100%',
    backgroundColor: 'rgba(156, 165, 236, 0.04)'
  },
  logout: {
    marginLeft: theme.spacing(1)
  },
  epicLogo: {
    padding: theme.spacing(4, 0, 3.2, 4.8)
  },
  list: {
    padding: theme.spacing(0)
  },
  divider: {
    opacity: 0.1,
    backgroundColor: '#D8D8D8'
  }
}));

const NavBar = ({ onMobileClose, openMobile, onLogout, budget, user }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* Left navigation */}
        <Box paddingRight={0}>
          {/* Logo */}
          <Box height={72} display="flex" alignItems="center" pl={5.4}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.epicLogo}>
            <Icon
              size="lg"
              url={user.logoUrl}
              width={35}
              active
              label={user.brand}
            />
          </Box>
          <List className={classes.list}>
            {NAVBAR_ROUTES.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={
                  location.pathname === item.href ? item.iconActive : item.icon
                }
              />
            ))}
          </List>
        </Box>
        {/* Budgets */}
        <Budget {...budget} />
      </Box>
      <Box flexGrow={1} />
      <Hidden lgUp>
        <IconButton color="secondary" onClick={onLogout}>
          <InputIcon />
          <Typography className={classes.logout} color="secondary" variant="h5">
            Logout
          </Typography>
        </IconButton>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onLogout: PropTypes.func,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.shape({
    logoUrl: PropTypes.string,
    brand: PropTypes.string
  }),
  budget: PropTypes.shape({
    budget: PropTypes.number,
    spent: PropTypes.number,
    spentPercentage: PropTypes.number
  })
};

NavBar.defaultProps = {
  onLogout: () => {},
  onMobileClose: () => {},
  openMobile: false,
  budget: {}
};

export default NavBar;
