import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(() => ({
  root: {
    height: 72,
    borderBottom: '0.5px solid #D8D8D8'
  }
}));

const MainNavbar = (props) => {
  const classes = useStyles();
  return (
    <AppBar elevation={0} className={classes.root} {...props}>
      <Toolbar sx={{ height: 72 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
