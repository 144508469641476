export const DEFAULT_ERROR_MESSAGE = 'Something went wrong please try again';

/**
 * Handle get error message from the API
 *
 * @param {Object} data
 *
 * @returns String
 */
export const getErrorMessage = (data) => {
  if (!data) {
    return '';
  }

  return data.errors && data.errors.length
    ? data.errors[0]
    : DEFAULT_ERROR_MESSAGE;
};
