import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILED,
  LOGOUT_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED
} from 'src/redux/actions/Auth';

export const initialState = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case LOGIN_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user,
        error: null
      };
    }

    case LOGIN_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error
      };
    }

    case LOGOUT_REQUEST: {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: null
      };
    }

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      };
    }

    case CHANGE_PASSWORD_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error
      };
    }

    default:
      return state;
  }
};
