import React, { memo } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, colors, ListItem, makeStyles } from '@material-ui/core';
import Icon from 'src/components/Icon';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    justifyContent: 'flex-start',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  title: {
    fontSize: 14,
    color: colors.common.white,
    opacity: 0.5,
    marginRight: 'auto'
  },
  active: {
    paddingLeft: theme.spacing(3.7),
    borderLeft: 'solid 3px #028BD2',
    background: '#28292b',
    '& $title': {
      opacity: 1
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({ className, href, icon, title, ...rest }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <ListItem
      activeClassName={classes.active}
      component={RouterLink}
      to={href}
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button className={classes.button}>
        <Icon
          active={pathname === href}
          label={title}
          url={icon}
          size="md"
          width={20}
        />
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default memo(NavItem);
