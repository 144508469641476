import {
  GET_PRESHOWS_FAILED,
  GET_PRESHOWS_REQUEST,
  GET_PRESHOWS_SUCCESS,
  GET_PRESHOW_DETAIL_FAILED,
  GET_PRESHOW_DETAIL_REQUEST,
  GET_PRESHOW_DETAIL_SUCCESS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
  GET_DEMOGRAPHICS_FAILED,
  GET_DEMOGRAPHICS_REQUEST,
  GET_DEMOGRAPHICS_SUCCESS,
  GET_WIDGETS_FAILED,
  GET_WIDGETS_REQUEST,
  GET_WIDGETS_SUCCESS,
  GET_SURVEY_RESULTS_REQUEST,
  GET_SURVEY_RESULTS_SUCCESS,
  GET_SURVEY_RESULTS_FAILED
} from '../actions/Preshow';

export const initialState = {
  isLoading: false,
  preshows: {
    data: [],
    metadata: {}
  },
  preshowDetail: {
    spots: [],
    analytics: {},
    demographics: {},
    widgets: {},
    surveyResults: []
  },
  error: null,
  detailError: {
    preshowDetail: null,
    analytics: null,
    demographics: null,
    widgets: null,
    surveyResults: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESHOWS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };

    case GET_PRESHOWS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshows: data
      };
    }

    case GET_PRESHOWS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error
      };
    }

    // Get preshow detail
    case GET_PRESHOW_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_PRESHOW_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshowDetail: {
          ...state.preshowDetail,
          ...data
        }
      };
    }

    case GET_PRESHOW_DETAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        detailError: {
          ...state.detailError,
          preshowDetail: error
        }
      };
    }

    // Get analytics
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_ANALYTICS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshowDetail: {
          ...state.preshowDetail,
          analytics: data
        }
      };
    }

    case GET_ANALYTICS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        detailError: {
          ...state.detailError,
          analytics: error
        }
      };
    }

    // Get demongraphics
    case GET_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_DEMOGRAPHICS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshowDetail: {
          ...state.preshowDetail,
          demographics: data
        }
      };
    }

    case GET_DEMOGRAPHICS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        detailError: {
          ...state.detailError,
          demographics: error
        }
      };
    }

    // Get widgets
    case GET_WIDGETS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_WIDGETS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshowDetail: {
          ...state.preshowDetail,
          widgets: data
        }
      };
    }

    case GET_WIDGETS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        detailError: {
          ...state.detailError,
          widgets: error
        }
      };
    }

    // Get survey results
    case GET_SURVEY_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_SURVEY_RESULTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        preshowDetail: {
          ...state.preshowDetail,
          surveyResults: data
        }
      };
    }

    case GET_SURVEY_RESULTS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        detailError: {
          ...state.detailError,
          surveyResults: error
        }
      };
    }

    default:
      return state;
  }
};
